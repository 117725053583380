import React from "react";

function PromotionView() {
  const handleGoBack = () => {
    window.history.back();
  };

  return (
    <div className=" lg:pt-[140px] pt-20 relative">
      
      <div className="flex justify-between xl:px-[120px] px-3 my-4">
        <button
          onClick={handleGoBack}
          className="flex gap-4 items-center cursor-pointer w-[100px]"
        >
          <svg
            width="24"
            height="25"
            viewBox="0 0 24 25"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M19 11.4997H7.82999L12.71 6.61967C13.1 6.22967 13.1 5.58967 12.71 5.19967C12.6175 5.10697 12.5076 5.03342 12.3866 4.98324C12.2656 4.93306 12.136 4.90723 12.005 4.90723C11.874 4.90723 11.7443 4.93306 11.6234 4.98324C11.5024 5.03342 11.3925 5.10697 11.3 5.19967L4.70999 11.7897C4.61728 11.8822 4.54373 11.9921 4.49355 12.113C4.44337 12.234 4.41754 12.3637 4.41754 12.4947C4.41754 12.6256 4.44337 12.7553 4.49355 12.8763C4.54373 12.9973 4.61728 13.1072 4.70999 13.1997L11.3 19.7897C11.3926 19.8823 11.5025 19.9557 11.6234 20.0058C11.7444 20.0559 11.8741 20.0817 12.005 20.0817C12.1359 20.0817 12.2656 20.0559 12.3865 20.0058C12.5075 19.9557 12.6174 19.8823 12.71 19.7897C12.8026 19.6971 12.876 19.5872 12.9261 19.4662C12.9762 19.3453 13.002 19.2156 13.002 19.0847C13.002 18.9537 12.9762 18.8241 12.9261 18.7031C12.876 18.5822 12.8026 18.4723 12.71 18.3797L7.82999 13.4997H19C19.55 13.4997 20 13.0497 20 12.4997C20 11.9497 19.55 11.4997 19 11.4997Z"
              fill="#202020"
            />
          </svg>
          <p className="text-[#202020] font-[600]">Back</p>
        </button>
        <img src="../assets/logo3.png" alt="" className="w-[200px]" />
      </div>

      <div className="mt-5">
        <div className="flex flex-col md:flexs-row justify-between items-start md:items-center">
          <div className="bg-[#139EB5] w-full">
            <div className="bg-white w-fit xl:mx-[120px] mx-3 px-2">
              <p className="text-[#11485B]  md:text-[22px] text-center font-[700]">
                Breast Cancer Awareness
              </p>

            </div>
          </div>

        </div>
        
        <div className="mt-4 xl:px-[120px] px-3">
        <img src="/assets/img-4.jpeg" alt="" className="w-full  " />

          <p className="text-[14px] md:text-[20px] text-[#83d284] py-2  font-semibold">
            This October: Let’s Turn Aqua Pink for Breast Cancer Awareness!{" "}
          </p>
          <p className="text-[14px] md:text-[20px] font-bold mt-3 text-[#A3D284] ">
            Early detection and prevention can save lives:{" "}
            <p className="font-normal rich-content">
              In support of Breast Cancer Awareness Month, Aqua Medical
              Laboratories is offering 15% off our Early Detection Factor
              profile of tests - available until{" "}
              <span className="font-bold">28th November</span>.
            </p>
          </p>
          <p className="text-[14px] md:text-[20px] font-bold mt-3 text-[#A3D284] ">
            Genetic markers
            <p className="font-normal rich-content">
              It may be as simple as making key lifestyle changes that could
              dramatically reduce your risk and save lives.
            </p>
          </p>

          <p className="text-[14px] md:text-[20px] font-bold mt-3 text-[#A3D284]">
            Our Offer
          </p>
          <ul className="text-[14px] md:text-[20px] rich-content">
            <li>
              <span className="font-bold">15% off </span>on Early Detection
              Factor profile
            </li>
            <li>Includes comprehensive screening of genetic markers</li>
            <li>Insightful recommendations for lifestyle changes</li>
            <li>
              Offer valid until <span className="font-bold">28th November</span>
            </li>
          </ul>
          <div class="overflow-x-auto mt-5">
            <table class="min-w-full border border-gray-300 rich-content">
              <tbody>
                <tr class="font-normal hover:bg-gray-100">
                  <td class="border border-gray-300 px-2 py-2">1</td>
                  <td class="border border-gray-300 px-2 py-2">
                    21 Working days
                  </td>
                  <td class="border border-gray-300 px-2 py-2">
                    BRCA1 & BRCA2 gene analysis
                  </td>
                  <td class="border border-gray-300 px-2 py-2">Oncology</td>
                  <td class="border border-gray-300 px-2 py-2">
                    Provide histopathological report of patient (in primary
                    cases) and relevant family history in case of first line
                    relative screening
                  </td>
                  <td class="border border-gray-300 px-2 py-2">
                    Next Generation Sequencing
                  </td>
                  <td class="border border-gray-300 px-2 py-2">Pounds 270</td>
                </tr>
                <tr class="hover:bg-gray-100">
                  <td class="border border-gray-300 px-2 py-2">2</td>
                  <td class="border border-gray-300 px-2 py-2">
                    21 working days
                  </td>
                  <td class="border border-gray-300 px-2 py-2">
                    Comprehensive tumor panel
                  </td>
                  <td class="border border-gray-300 px-2 py-2">Oncology</td>
                  <td class="border border-gray-300 px-2 py-2">
                    Confirmatory clinical diagnosis of malignancy is a mandatory
                    requirement. Additionally, we require histopathology report
                    for solid
                  </td>
                  <td class="border border-gray-300 px-2 py-2">
                    Next generation sequencing
                  </td>
                  <td class="border border-gray-300 px-2 py-2">Pounds 490</td>
                </tr>
                <tr class="hover:bg-gray-100">
                  <td class="border border-gray-300 px-2 py-2">3</td>
                  <td class="border border-gray-300 px-2 py-2">2-3 weeks</td>
                  <td class="border border-gray-300 px-2 py-2">
                    Circulating tumor cells
                  </td>
                  <td class="border border-gray-300 px-2 py-2">Oncology</td>
                  <td class="border border-gray-300 px-2 py-2">
                    Liquid base (blood test)
                  </td>
                  <td class="border border-gray-300 px-2 py-2">
                    Low cytometry
                  </td>
                  <td class="border border-gray-300 px-2 py-2">1150 pounds</td>
                </tr>
                <tr class="hover:bg-gray-100">
                  <td class="border border-gray-300 px-2 py-2">4</td>
                  <td class="border border-gray-300 px-2 py-2">5-10 days</td>
                  <td class="border border-gray-300 px-2 py-2">
                    Serum breast tumor screening
                  </td>
                  <td class="border border-gray-300 px-2 py-2">Oncology</td>
                  <td class="border border-gray-300 px-2 py-2">
                    Screening blood test (CA 125, CA15-3, CEA)
                  </td>
                  <td class="border border-gray-300 px-2 py-2">ELISA</td>
                  <td class="border border-gray-300 px-2 py-2">130 pounds</td>
                </tr>
              </tbody>
            </table>
          </div>

          <p className="text-[14px] md:text-[20px] font-bold mt-4 text-[#A3D284] ">
            Take control of your health.{" "}
            <p className="font-normal rich-content">
              Understanding your genetic risk and making simple adjustments
              today could change your future. Together, we’ll turn Aqua pink
              this month, raising awareness and taking action in the fight
              against breast cancer.
            </p>
          </p>

          <p className="text-[14px] md:text-[20px] font-bold mt-3 rich-content ">
            {" "}
            Book your test today - early detection is key.
          </p>
        </div>
      </div>

      <div className="bg-[#139EB5] w-full h-[40px]"></div>
    </div>
  );
}

export default PromotionView;
